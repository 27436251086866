 
/* Low resolution Tablets and iPads */
@media (max-width: 767px) {
    .d-tablet {
        display: block;
    }
    .d-tablet-none{
        display: none !important;
    }

    .navbar {
        padding: 0.35rem 0;
        display: flex;
        justify-content: space-between;
    
        & .navbar-brand {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            font-size: calc($font-size * 1.5);
        }
    
        & .navbar-section{
            & .navbar-list {
                & .navbar-items {
                    display: inline-block;
                    list-style: none;
                    margin-right: 0.25rem;
                    & .navbar-item{
                        text-align: center;
                        color: rgb($white, 0.8);
                        font-size: 0.85rem;
                        text-transform: uppercase;
                        font-weight: $bold;
                        text-decoration: none;
                        padding: 0.75rem 0.5rem;
                        transition: color 1ms ease-in-out,
                                    border 1ms ease-in-out;
                        &:hover,
                        &:active{
                            color: $white;
                        }
                    }
                    & .active {
                        color: $white;
                        border-bottom: 2px solid $white;
                    }
                }
            }
        }
    }

    .page-caption {
        display: block;
        .page-caption-header{
            display: block;
            width: 100%;
            min-width: none;
            max-width: none;
            & .page-caption-text {
                font-size: 1.25rem;
                line-height: 2.25rem;
            }

        }
        & .page-caption-figure {
            margin-top: 2rem;
            width: 100%;
            height: 275px;
            min-width: none;
            max-width: none;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .listing-grid {
        & .card {
            & .card-img {
                width: 30%;
                height: auto;
            }
        }
    }

    footer {
        font-size: 1rem;
    }

    .page-caption-e {
        display: block;
        .page-caption-header-e{
            display: block;
            width: 100%;
            min-width: none;
            max-width: none;
            & .page-caption-text-e {
                font-size: 1rem;
                line-height: 2rem;
            }
        }
    }
}