@import 'bootstrap';

// Duration
$duration: 0.5s;

// Font Family
$body-font: "Roboto";

// Font Weights
$thin: 100;
$light: 300;
$regular: 400;
$semibold: 500;
$bold: 700;
$ultra: 800;

// Base
$base-background: #ffffff;
$base-font-family: $body-font, sans-serif;
$font-size: 16px;
$base-font-weight: $regular;
$base-line-height: 1.5em;

// Colors
$black: #0b0d22;
$dark-gray: rgba($black, 0.8);
$gray: rgba($black, 0.6);
$light-gray: rgba($black, 0.4);
$lighter-gray: rgba($black, 0.2);
$white: #FFFFFF;
$primary: #1976D2;
$primary-brand: #1a76d2;
$primary-outline-background: #ecf6fe;
$primary-border: #1a76d2;
$secondary: #41434d;
$warning: #fab700;

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: $base-background;
  color: $secondary;
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: $duration ease;
  @media #{$mq-medium} {
    font-size: $font-size*1.2;
    line-height: $base-line-height*1.2;
  }

  @media #{$mq-large} {
    font-size: $font-size*1.3;
  }

  @media #{$mq-xlarge} {
    font-size: $font-size*1.4;
  }

  @media #{$mq-xxlarge} {
    font-size: $font-size*1.6;
  }
}

.color-white{
  color: $white;
}
.color-primary{
  color: $primary-brand;
}

.bg-primary{
  background-color: $primary-brand;
}

.d-desktop-none{
  display: none;
}

div.divider-primary{
  position: relative;
  margin: 1rem auto;
  border-top: 0.1rem solid $primary;
  background-color: $primary;
  line-height: 0.5rem;
}


.listing-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  & .card {
    align-items: center;
    & .card-img{
      width: 30%;
      height: auto;
    }
    & .card-title {
      font-weight: $bold;
      text-transform: uppercase;
      color: $primary;
    }
    & .btn-primary{
      background-color: $primary;
      font-size: 0.8rem
    }
  }
}

.model-recognition {
  background-color: $primary;
  border-radius: 25px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  z-index: 9999;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
              0px 6px 10px rgba(0, 0, 0, 0.14),
              0px 1px 18px rgba(0, 0, 0, 0.12);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  padding: 2em;
  padding-top: 2em;
  justify-content: center;
}

.disabled {
  display: none
}

.image-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3vmax;
  border: 2px dashed;
  border-radius: 8px;
  border-color: $primary-border;
}

@media (max-width: 760px) {
  .mobileCenter {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
}


@import './components/navbar';
@import './components/pageCaption';
@import './components/filters';
@import './components/footer';

@import './responsive/tablet';
@import './responsive/mobile';