.navbar {
    padding: 0;
    box-shadow: 0px 2px 16px 0px #22185780;
    & .hamburger {
        display: none;
    }

    & .navbar-brand {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        color: $white;
        font-weight: $bold;
        font-size: calc($font-size * 2.5);
    }

    & .navbar-section{
        & .navbar-list {
            margin: 0;
            padding: 0;
            list-style: none;
            & .navbar-items {
                display: inline-block;
                list-style: none;
                margin-right: 0.5rem;
                & .navbar-item,
                & .nav-item.dropdown > a {
                    text-align: center;
                    color: rgb($white, 0.8);
                    font-size: 0.95rem;
                    text-transform: uppercase;
                    font-weight: $bold;
                    text-decoration: none;
                    padding: 1.4rem 1rem;
                    display: block;
                    border-bottom: 2px solid transparent;
                    transition: color 1ms ease-in-out,
                                border 1ms ease-in-out;
                    &:hover,
                    &:active{
                        color: $white;
                        border-bottom: 2px solid $white;
                    }
                    & span {
                        text-transform: lowercase;
                    }
                }
                & .active {
                    color: $white;
                }
            }
        }
    }
}


.mobileNav{
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    & .navbar-section{
        width: 60%;
        & .navbar-list {
            margin: 0;
            padding: 0;
            & .navbar-items {
                list-style: none;
                border-bottom: 2px solid $white;
                & .navbar-item,
                & .nav-item.dropdown > a {
                    color: rgb($white, 0.8);
                    font-size: 0.95rem;
                    text-transform: uppercase;
                    font-weight: $bold;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    display: block;
                    border-bottom: 2px solid transparent;
                    transition: color 1ms ease-in-out,
                                border 1ms ease-in-out;
                    &:hover,
                    &:active{
                        color: $white;
                        background-color: rgba(255,255,255,0.15);
                    }
                    & span {
                        text-transform: lowercase;
                    }
                }
                & .active {
                    color: $white;
                }
            }
        }
    }
}