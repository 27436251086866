.page-caption {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .page-caption-header{
        display: flex;
        flex-direction: column;
        min-width: 60%;
        width: 100%;
        & .page-caption-text {
            font-size: calc(2vw);
        }

        & .page-caption-heading{
            color: $primary;
            font-weight: $bold;
            font-size: calc(6vmin);
            line-height: calc(4.6vmax);
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            display: flex;
        }
    }

    & .page-caption-figure {
        margin: 0;
        padding: 0;
        min-width: 30%;
        width: 100%;
        max-width: 40%;
        & img {
            width: 100%;
            object-fit: cover;
        }
    }
}



.page-caption-e {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
    .page-caption-header-e{
        display: flex;
        flex-direction: column;
        min-width: 60%;
        width: 100%;
        max-width: 70%;

        & .page-caption-text-e {
            font-size: calc(2.5vw);
            display: flex;
            flex-direction: column;
            align-items:stretch;
        }

        & .page-caption-heading-e{
            color: $primary;
            font-weight: $bold;
            font-size: calc(4.3vw);
            line-height: calc(5.2vw);
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            display: flex;
        }
        & .HELLO{
            color: $warning;
            font-weight: $bold;
            font-size: calc(4.3vw);
            line-height: calc(5.2vw);
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            display: flex;
            text-align: center;
            align-self: center;
        }
    }


    
    .page-caption-b {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        margin-bottom: 2rem;
        .page-caption-header-e{
            display: flex;
            flex-direction: column;
            min-width: 60%;
            width: 100%;
            max-width: 70%;
    
            & .page-caption-text-b {
                font-size: calc(2.5vw);
                display: flex;
                flex-direction: column;
                align-items:stretch;
            }
    
            & .page-caption-heading-b{
                color: $primary;
                font-weight: $bold;
                font-size: calc(4.3vw);
                line-height: calc(5.2vw);
                text-transform: capitalize;
                margin: 0;
                padding: 0;
                display: flex;
            }
          
        }
    }

   
}



