@tailwind base;
@tailwind components;
@tailwind utilities;

.card-custom {
    @apply block max-w-sm bg-white border border-gray-200 rounded-lg shadow
}

.dot {
    @apply bg-blue-800 w-3 h-3 rounded-lg
}

.input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
}

.scroll {
    /* width: 200px;
    height: 400px; */
     /* background: red; */
    overflow: scroll;
}
.scroll::-webkit-scrollbar {
    width: 2px; 
}

.scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
}