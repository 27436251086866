/* Mobile Devices */
@media (max-width: 480px) {
    .d-mobile-none{
        display: none !important;
    }

    .navbar {
        & .hamburger {
            display: inline-flex !important;
            // display: navbar-items !important;
            margin-left: 1rem;
        }
    }

    .listing-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1rem;
    }

    .page-caption {
        
        .page-caption-header{
            
    
            & .page-caption-text {
                font-size: calc(4vw);
            }


    footer {
        font-size: 1rem;
    }
    }
}

.page-caption-e {
        
    .page-caption-header-e{
        

        & .page-caption-text-e {
            font-size: calc(3.5vw);
        }
    }
}
}