.filter-listing{
    & .filters{
        margin: 1rem 0;
        & a{
            text-decoration: none;
            border: 1px solid $primary;
            border-radius: 25px;
            padding: 0.1rem 1rem;
            margin: 0.15rem;
            font-size: 1rem;
            font-weight: bold;
            display: inline-block;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-right: 0;
            }

            &:hover{
                background-color: $primary-outline-background;
            }

            &.active{
                background-color: $primary-outline-background;
            }
        }
    }

    // & .filter-carosal {
    //     display: inline-flex;
    //     & .card {
    //         margin: 1rem 0;

    //         &:first-child{
    //             margin-left: 0;
    //         }
    //         &:last-child{
    //             margin-right: 0;
    //         }
    //     }
    // }
}